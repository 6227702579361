/* Provide sufficient contrast against white background */
.asyntec-portal-clientes a {
  color: #0366d6;
}

.asyntec-portal-clientes code {
  color: #E01A76;
}

.asyntec-portal-clientes .btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.asyntec-portal-clientes .bordeIzquierdo {
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 15px solid #1b6ec2;
  border-radius: 5px 0px 0px 5px;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.asyntec-portal-clientes .bordeIzquierdoSimple {
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-radius: 5px 0px 0px 5px;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.asyntec-portal-clientes .bordeDerecho {
  border-style: solid;
  border-radius: 0px 5px 5px 0px;
  border-color: #d9d9d9;
  border-width: 1px 1px 1px 0px;
  margin-bottom: 15px;
  padding-top: 1em;
  padding-bottom: 1em;
  background-color: #ffffff;
}

.asyntec-portal-clientes .bordeCompleto {
  border-right: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border-radius: 5px 5px 5px 5px;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 15px;
  background-color: #ffffff;
}

.griddle-pagination.row{
  margin-left: 0;
  margin-right: 0;
}

.asyntec-portal-clientes .opcionesPago .opcionPagar{
  background-color: inherit;
  padding: 1rem;
  border: 1px solid #d6d8db;
}

.asyntec-portal-clientes .opcionesPago .opcionPagar.seleccionada{
  background-color: #cce5ff;
  border: 1px solid #b8daff;
}

.asyntec-portal-clientes .cuadroPago{
  background-color: #F8F8FA;
  padding:1rem;
}

.asyntec-portal-clientes input::-webkit-outer-spin-button,
.asyntec-portal-clientes input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.asyntec-portal-clientes input[type=number] {
  -moz-appearance: textfield;
}

.asyntec-portal-clientes .grafico-barras1 .recharts-rectangle{
  fill: #1b6ec2;
}

.asyntec-portal-clientes .titulo-modal{
  color: #ffffff;
  background-color: #1b6ec2;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-right: 2em;
  padding-left: 2em;
  font-size: larger;
}
.asyntec-portal-clientes .cuerpo-modal{
  background-color: #EDEDEF;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 2em;
  padding-left: 2em;
  font-size: medium;
}
.asyntec-portal-clientes .cuerpo-modal input{
  padding-top: 0;
  padding-bottom: 0;
  /* padding-top: 0; */
}

.asyntec-portal-clientes #asyntecPortalDVServicio {
  background-color: #ffffff;
}
.asyntec-portal-clientes #asyntecPortalDVServicio .btn{
  color: #000;
  border: 1px solid #bbbbbb;
}

.asyntec-portal-clientes .TerminosCondiciones div[class*="col-"]{
    max-height: calc(100vh - 90px);
    overflow: auto;
}


.asyntec-portal-clientes .react-autosuggest__suggestions-container{
  padding:0;
  list-style: none;
  width: 100%;
}
.asyntec-portal-clientes .react-autosuggest__suggestions-container li{
  padding:5px;
  cursor:pointer;
}
.asyntec-portal-clientes .react-autosuggest__suggestion--focused{
  font-weight: bold;
}
.asyntec-portal-clientes .react-autosuggest__suggestions-container{
  position: absolute;
  max-height: 300px;
  background: #fff;
  overflow-y: auto;
  -webkit-box-shadow: 0 2px 7px rgba(0,0,0,0.5);
  box-shadow: 0 2px 7px rgba(0,0,0,0.5);
  z-index: 1;
}